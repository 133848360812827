

export const convertOffers = ({ valueStr }) => {

    let offers;

    if (valueStr) {
        if (typeof valueStr === 'object') {
            console.info("Already an object");
            offers = { ...valueStr };
        } else {
            try {
                offers = JSON.parse(valueStr);
            } catch (err) {
                console.warn("No offers", valueStr, err);
                offers = {}
            }
        }

    }


    // // we convert the weird Athena format to an object

    // let fields = ['norider', 'nonsmoker', 'term10', 'term20', 'smoker', 'rider', 'monthly', 'proposed', 'recommended', 'base'];
    // let value = valueStr.replaceAll('=', ':');
    // for (let field of fields) {
    //     value = value.replaceAll(field, '"' + field + '"');
    // }
    // value = value.replaceAll('"no"', '"no').replaceAll('"non"', '"non').replaceAll('""', '"');
    // let offers = JSON.parse(value);

    return offers;

}
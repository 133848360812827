import React, {
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  Badge,
  Card,
  Col,
  Container,
  Row,
} from 'react-bootstrap';

import { ReportingDataContext } from '../libs/contexts.js';

export const PipelineSummary = ({ }) => {

    const { reportingData } = useContext(ReportingDataContext);
    const [stages, setStages] = useState({});

    useEffect(() => {
        if (reportingData.brokers) {
            let newStages = {};
            for (let broker of reportingData.brokers) {
                if (undefined !== broker.stage) {
                    if (undefined === newStages[broker.stage]) {
                        newStages[broker.stage] = [];
                    }
                    switch (broker.stage) {
                        case 'participating':
                            broker.bg = 'success';
                            broker.caption = 'Participating';
                            break;
                        case 'inception':
                            broker.bg = 'warning';
                            broker.caption = 'Released but not active yet';
                            break;
                        case 'confirmed':
                        case 'committed':
                            broker.bg = 'info';
                            broker.caption = 'Interested';
                        case 'considered':
                        case 'ended':
                            broker.bg = 'primary';
                            broker.caption = 'Considered';
                            break;
                        default:
                            broker.bg = 'danger';
                            broker.caption = 'To be confirmed';
                            break;

                    }
                    newStages[broker.stage].push(broker);
                }
            }
            setStages(newStages);
        }
        console.log("Brokers", reportingData.brokers);
    }, [reportingData.brokers])

    return (
        <Card className='card-summary-alt'>
            <Card.Header>Onboarding pipeline</Card.Header>
            <Card.Body>
                <Container fluid>


                    <Row className='mt-4 mb-4'>
                        <Col className='text-center'>
                            { stages &&
                                <>
                                    { Object.keys(stages).map((stage) => (
                                        <React.Fragment key={ stage }>
                                            { stages[stage].map((broker) => (
                                                <Badge title={ broker.caption } key={ broker.id } bg={ broker.bg } className='badge-summary'>{ broker.name }</Badge>
                                            )) }
                                        </React.Fragment>

                                    )) }
                                </> }
                        </Col>
                    </Row>
                </Container>
            </Card.Body>
            {/* <Card.Footer className='text-end'>
                <Button variant="outline-primary" className='btn-icon'><FaArrowRight /></Button>
            </Card.Footer> */}
        </Card>

    );
}


import React, {
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  Col,
  Container,
  Row,
} from 'react-bootstrap';
import { MdOutlineCloudSync } from 'react-icons/md';
import {
  BrowserRouter,
  Route,
  Routes,
} from 'react-router-dom';

import { ActionCard } from '../controls/cards';
import { Dashboard } from '../dashboard/dashboard';
import { Downloads } from '../downloads/main';
import { Lead } from '../leads/lead';
import { Leads } from '../leads/leads';
import * as api from '../libs/api';
import * as contexts from '../libs/contexts';
import * as ui from '../libs/ui';
import { Reports } from '../reports/main';
import { SalesArguments } from '../settings/closing';
import {
  LoginPage,
  LogoutPage,
  RedirectPage,
} from './login';
import {
  Page,
  SecurePage,
} from './page';

export const ControlRoom = ({ query, desktop }) => {


    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path="/login" element={ <LoginPage /> } />
                    <Route path="/logout" element={ <LogoutPage /> } />
                    <Route path="/redirect" element={ <RedirectPage /> } />
                    <Route path="/redirect.html" element={ <RedirectPage /> } />
                    <Route path="/" element={ <Page query={ query } desktop={ desktop } /> }>
                        <Route index element={ <Dashboard /> } />
                        <Route path="dashboard" element={ <LoginPage /> } />
                        <Route path="login" element={ <LoginPage /> } />
                        <Route path="welcome" element={ <RedirectPage /> } />
                        <Route path="leads" element={ <Leads /> } />
                        <Route path="leads/:id" element={ <Lead /> } />
                        <Route path="reports" element={ <Reports /> } />
                        <Route path="downloads" element={ <Downloads /> } />
                        <Route path="closing" element={ <SalesArguments /> } />
                    </Route>
                    <Route path="*" element={ <RedirectPage /> } />

                </Routes>
            </BrowserRouter>


        </>
    )

}

export const AppSession = ({ query, desktop }) => {

    const { session, setSession } = useContext(contexts.SessionContext);
    const { settings, setSettings } = useContext(contexts.SettingsContext);
    const { layout, setLayout } = useContext(contexts.LayoutContext);
    const { reportingData, setReportingData } = useContext(contexts.ReportingDataContext);
    const [hasData, setHasData] = useState(false);

    useEffect(() => {

        ui.getLayout((data) => {
            data.desktop = desktop;
            data.query = query;
            setLayout(data);
        });


    }, []);


    useEffect(() => {

        //onsole.info("Updating session", session);

        api.getSettings((data) => {
            setSettings(data);
        });


        if (undefined !== session && session.email) {
            //onsole.log("Ready to grab data", session);
            //onsole.log("main token", session.token);
            api.getData({
                token: session.token, next: (data) => {
                    //onsole.log("Username", session.email);
                    setReportingData(data);
                    setHasData(true);

                }, verbose: true
            });

            api.getSettings((data) => {

                setSettings(data);
            });

        }

    }, [session]);

    useEffect(() => {

        //onsole.info("Checking reporting data", reportingData);

        if (reportingData) {
            console.info("Cfg", reportingData.customization);
            console.info("Demographics", reportingData.demographics);
            console.info("Policies", reportingData.policies);
        }

    }, [reportingData]);

    return (
        <>  { hasData ?
            <SecurePage />
            :
            <WaitingScreen />
        }

        </>
    )

}

const WaitingScreen = () => {


    return (
        <Container>
            <Row className="mt-4">
                <Col className="col-md-6 offset-md-3">
                    <ActionCard
                        title="Just a moment"
                        text="We're gathering the latest metrics and insights..."
                        icon={ <MdOutlineCloudSync /> }
                    />
                </Col>
            </Row>
        </Container>

    );

}
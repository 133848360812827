import {
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  DropdownButton,
  Image,
  Row,
} from 'react-bootstrap';
import {
  Link,
  useLocation,
} from 'react-router-dom';

import * as logo from '../bootstrap/logo.png';
import * as config from '../config.js';
import {
  ReportingDataContext,
  SessionContext,
} from '../libs/contexts.js';

export const TopMenu = ({ }) => {

    const { session } = useContext(SessionContext);
    const { reportingData } = useContext(ReportingDataContext);
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [showDownloads, setShowDownloads] = useState(0);
    const location = useLocation();

    useEffect(() => {





    }, []);

    useEffect(() => {

        if (reportingData.policies && reportingData.policies.length > 0) {
            setShowDownloads(reportingData.policies.length);
        }

    }, [reportingData.policies]);

    useEffect(() => {

        if (session.email && reportingData.customization) {
            if (reportingData.customization.subscriptions) {
                console.info("Subs", reportingData.customization.subscriptions);
                for (let subscriber of reportingData.customization.subscriptions) {
                    if (subscriber === session.email) {
                        setIsSubscribed(true);
                    }
                }
            }
        }

    }, [reportingData.customization, session.email]);

    return (

        <Container fluid className="menu">
            <Row className="mt-2 mb-2 px-0 mx-0">
                <Col className='text-center'>
                    { config.TopMenuLink1Url &&
                        <Button href={ config.TopMenuLink1Url } className="btn-menu mx-2">{ config.TopMenuLink1Caption }</Button>
                    }
                    <Button className="btn-menu-active mx-2">Link</Button>
                    { config.TopMenuLink2Url &&
                        <Button href={ config.TopMenuLink2Url } className="btn-menu mx-2">{ config.TopMenuLink2Caption }</Button>
                    }
                </Col>
            </Row>
            <Row>
                <Col className="px-0 mx-0">
                    <Card className='card-top'>
                        <Card.Body>
                            <Container fluid>
                                <Row>
                                    <Col className='col-7'>
                                        <a target="_blank" href="/" rel="noopener noreferrer">
                                            <Image className="broker-logo" src={ logo } />
                                        </a>
                                    </Col>
                                    <Col className='col-4 text-end'>
                                        <DropdownButton title="Quick access">
                                            <Dropdown.Item style={ ['', '/', '/welcome', '/dashboard'].includes(location.pathname) ? { fontStyle: 'italic' } : {} } as={ Link } to="/">
                                                Dashboard
                                            </Dropdown.Item>
                                            <Dropdown.Item as={ Link } style={ location.pathname.includes('leads') ? { fontStyle: 'italic' } : {} } to="/leads">
                                                Leads
                                            </Dropdown.Item>
                                            { showDownloads > 0 &&
                                                <Dropdown.Item as={ Link } style={ location.pathname.includes('downloads') ? { fontStyle: 'italic' } : {} } to="/downloads">
                                                    Downloads ({ showDownloads })
                                                </Dropdown.Item>
                                            }
                                            <Dropdown.Item as={ Link } to="/logout">
                                                Sign off
                                            </Dropdown.Item>
                                        </DropdownButton>
                                    </Col>
                                    {/* <Col className='text-start'>
                                        <DropdownButton title="Preferences">
                                            <Dropdown.Item className='disabled' disabled>
                                                { isSubscribed ?
                                                    <><IoMdNotificationsOn /> Email notifications </>
                                                    :
                                                    <><IoMdNotificationsOff /> Email notifications </>
                                                }
                                            </Dropdown.Item>
                                        </DropdownButton>
                                    </Col> */}
                                </Row>
                            </Container>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>)
};

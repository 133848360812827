import * as React from 'react';

import * as ReactDOM from 'react-dom/client';

import { ControlRoom } from './app/main.jsx';

// we allow for some special runtime conditions via the query string or local storage
let params = new URLSearchParams(window.location.search);
let entries = params.entries();
let query = Object.fromEntries(entries);


// check for mobile
let isDesktop = !(window.matchMedia("(max-width: 480px)").matches);

ReactDOM.createRoot(document.getElementById("app")).render(
    <ControlRoom query={ query } desktop={ isDesktop } />
);


